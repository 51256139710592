import React, { useState, useEffect } from "react";

import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./Gallery.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import galleryItems from "../../services/galleryJson";

function Gallery(props) {
  const [items, setItems] = useState([]);
  const checkAttributeExists = (price) => {
    if (price == "") {
      return false;
    } else return true;
  };

  useEffect(() => {
    setItems(galleryItems.galleryJson);
    console.log(galleryItems);
  });

  return (
    <React.Fragment>
      <Nav />

      <div className="container gallery-container">
        <h1 className="custom-header gallery-header">Tuxedo Gallery</h1>
        <div className="row">
          {items?.map((item, idx) => (
            // 12-single, 6-double, 4-triple, 3-quad,
            <Col xs={12} sm={12} md={6} lg={4} key={idx}>
              <div className="gallery-img-container">
                <img className="gallery-img" src={item.imageLink}></img>
              </div>
              <div className="img-title">
                <span style={{ fontWeight: "bold" }}>{item.title}</span>
                {checkAttributeExists(item.price) && (
                  <>
                    <br />
                    <span>${item.price}</span>
                  </>
                )}
                {checkAttributeExists(item.description) && (
                  <>
                    <br />
                    <span style={{ fontSize: 12 }}>{item.description}</span>
                  </>
                )}
              </div>
            </Col>
          ))}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
export default Gallery;
