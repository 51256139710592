import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Home.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ImageCarouselHeader from "../ImageCarouselHeader/ImageCarouselHeader";
import pinkVestImage from "../../resources/pink-vest.jpg";
import customTailorImage from "../../resources/custom-tailor.jpg";
import tuxImage from "../../resources/tux-img.jpg";

function Home(props) {
  return (
    <React.Fragment>
      <Nav />
      <ImageCarouselHeader />
      <div className="container home-container">
        <div className="content-wrapper">
          {/* <img src={devicesImage} className="home-image" /> */}
          <h1 className="home-header">
            Tuxedos and Suits, <br></br>Rentals and Sales
          </h1>
          <br></br>
          <p className="">
            Bowties Tuxedo is a family owned and operated business. We have been
            in the wedding industry for many years in the Las Vegas valley. We
            are conveniently located near McCarran airport, and just off the Las
            Vegas Strip. We offer a large in stock selection of colors and sizes
            for all occasions. All of our tuxedo rentals come complete with
            shoes at no additional cost. We offer in-room fittings by appt. and
            delivery and pick up services as well. Feel free to drop by anytime,
            or give us a call. We look forward to meeting you!
          </p>
          {/* <h2 className="info">Suit and Tuxedo Rentals and Sales</h2> */}
          <br></br>
          <address>
            <a
              href="https://goo.gl/maps/nNK2hePxssNpaD3aA"
              className="outerlink"
            >
              3899 E Sunset Rd #102, Las Vegas, NV 89120
            </a>
            <br></br>
            <br></br>
            <br></br>
            <abbr title="Phone">Phone:</abbr>
            <a href="tel:+17028894968" className="outerlink">
              (702) 889-4968
            </a>
            <br></br>
            <br></br>
            <p>
              Feel free to call or text after business hours for info: <br></br>
              Jason:
              <a href="tel:+17028894968" className="outerlink">
                702-285-6033
              </a>
            </p>
            <br></br>
          </address>
          <div className="accessory-colors-btn-container">
            <a
              href="https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/psf-2020-solid-swatch-card.pdf"
              className="accessory-colors-btn"
            >
              View Accessory Colors
            </a>
          </div>

          <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center text-light services-container">
            <div className="row">
              <div className="col-lg-4">
                <div className="category-img-container">
                  <img src={pinkVestImage} className="category-img" />
                </div>
                <h2 className="about-job-header">Vests</h2>
                <p className="about-description">
                  We have over 90 different vest colors in stock and custom
                  colors can be ordered upon request.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="category-img-container">
                  <img src={customTailorImage} className="category-img" />
                </div>
                <h2 className="about-job-header">Custom Tailoring</h2>
                <p className="about-description">
                  Get measured by a professional. Bowties Tuxedos are always the
                  perfect fit.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="category-img-container">
                  <img src={tuxImage} className="category-img" />
                </div>
                <h2 className="about-job-header">Premium Quality</h2>
                <p className="about-description">
                  Bowties Tuxedo always has the best quality, selection and
                  styles at affordable prices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Home;
