import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";

function Footer(props) {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-wrapper">
          {/* <img src={cubeLogo} className="footer-company-icon"></img>
          <p className="footer-company-name">kbd web design</p> */}

          <div className="footer-icons">
            <a
              href="https://www.instagram.com/bowtiestuxedo_702_889_4968/"
              target="_blank"
            >
              <i className="fa fa-instagram footer-icon"></i>
            </a>
            <a
              href="https://www.yelp.com/biz/bowties-tuxedo-las-vegas-3"
              target="_blank"
            >
              <i className="fa fa-yelp footer-icon"></i>
            </a>
            <a href="https://www.facebook.com/Bowtiestux" target="_blank">
              <i className="fa fa-facebook footer-icon"></i>
            </a>
          </div>
          <p className="footer-text">&copy; 2020 Bowties Tuxedo</p>
          <p className="powered-text">
            Powered by{" "}
            <a href="https://cloudsurfsoftware.com/" target="_blank">
              CloudSurf
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
