import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "./Measurements.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import { checkValidEmail, checkValidName } from "../../services/util";

function Measurements(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [weddingDate, setWeddingDate] = useState("");
  const [phone, setPhone] = useState("");
  const [chest, setChest] = useState("");
  const [overarm, setOverarm] = useState("");
  const [waist, setWaist] = useState("");
  const [outseam, setOutseam] = useState("");

  const [neck, setNeck] = useState("");
  const [shoe, setShoe] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");

  const [sleeveLength, setSleeveLength] = useState("");

  const [showSendingAlert, setShowSendingAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const defaultErrorMessage = "Something went wrong...";
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  useEffect(() => {
    setShowFailAlert(false);
  }, [name]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [email]);

  const handleSubmit = () => {
    const templateId = "bowties-tuxedo-measurements-template";

    let validEmail = checkValidEmail(email);
    let validName = checkValidName(name);

    if (validEmail && validName) {
      setShowFailAlert(false);
      setErrorMessage(defaultErrorMessage);
      sendFeedback(templateId, {
        message_html: message,
        from_name: name,
        reply_to: email,
        wedding_date: weddingDate,
        phone_number: phone,
        chest: chest,
        overarm: overarm,
        waist: waist,
        outseam: outseam,
        neck: neck,
        sleevelength: sleeveLength,
        shoe: shoe,
        height: height,
        weight: weight,
      });

      setName("");
      setEmail("");
      setPhone("");
      setWeddingDate("");
      setChest("");
      setOverarm("");
      setWaist("");
      setNeck("");
      setShoe("");
      setHeight("");
      setWeight("");
      setMessage("");
    } else {
      console.log("setting error message?");
      if (!validEmail) {
        setErrorMessage("Invalid Email Address");
      }
      if (!validName) {
        setErrorMessage("Name cannot be empty");
      }
      console.log("showing fail alert");
      setShowFailAlert(true);
    }
  };

  const sendFeedback = (templateId, variables) => {
    setShowSendingAlert(true);
    window.emailjs
      .send("cs_gmail", templateId, variables)
      .then((res) => {
        console.log("Email successfully sent!");
        setShowSuccessAlert(true);
        setShowSendingAlert(false);
        setTimeout(function () {
          setShowSuccessAlert(false);
        }, 3000);
      })
      .catch((err) => {
        setShowFailAlert(true);
        setTimeout(function () {
          setShowFailAlert(false);
        }, 3000);
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  };

  return (
    <React.Fragment>
      <Nav />
      <div className="container measurements-container">
        <div className="content-wrapper form-container">
          <h1 className="custom-header measurements-header">
            Send Your Measurements to Bowties Tuxedos
          </h1>
          <div className="text-left">
            <form>
              <div className="form-group">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Wedding Date</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Wedding Date"
                  value={weddingDate}
                  onChange={(e) => setWeddingDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Chest Measurement</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Chest Measurement"
                  value={chest}
                  onChange={(e) => setChest(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Overarm Measurement</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Overarm Measurement"
                  value={overarm}
                  onChange={(e) => setOverarm(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Waist Measurement</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Waist Measurement"
                  value={waist}
                  onChange={(e) => setWaist(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Outseam</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Outseam Measurement"
                  value={outseam}
                  onChange={(e) => setOutseam(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Neck Measurement</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Neck Measurement"
                  value={neck}
                  onChange={(e) => setNeck(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Sleeve length</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Sleeve Length Measurement"
                  value={sleeveLength}
                  onChange={(e) => setSleeveLength(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Shoe Size</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Shoe Size"
                  value={shoe}
                  onChange={(e) => setShoe(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Height</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Height"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Weight</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Weight"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Message</label>
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  placeholder="Add Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              {showSuccessAlert && (
                <div className="alert success-alert">
                  <p>Email successfully sent!</p>
                </div>
              )}

              {showSendingAlert && (
                <div className="alert sending-alert">
                  <p>Sending Email</p>
                  {/* <div className="lottie-container">
                    <UncontrolledLottie
                      lottieJson={loadingWheelLottie}
                      height={50}
                      width={50}
                    />
                  </div> */}
                </div>
              )}

              {showFailAlert && (
                <div className="alert fail-alert">
                  <p>{errorMessage}</p>
                </div>
              )}

              <input
                type="button"
                value="Submit"
                className="contact-btn"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
export default Measurements;
