import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./About.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import DevicesImg from "../../resources/devices.png";
import infoImg from "../../resources/info-icon.svg";

function About(props) {
  return (
    <React.Fragment>
      <Nav />
      <div className="container about-container">
        <div className="content-wrapper">
          <img src={infoImg} className="center-image" />

          <h1 className="custom-header">about</h1>
          <h2 className="info">
            kbd web design strives to bring you engaging web design and
            development centered around your brand identity.
          </h2>
          <button className="contact-button">coming soon</button>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default About;
