import React, { useState } from "react";
import "./Nav.css";
import NavItem from "./NavItem";
// import suitLogo from "../../resources/suit-icon-simple.svg";

// import threeDCubeImage from "../../resources/3d-cube.svg";

// import Home from "../Home/Home";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [colNum, setColNum] = useState(6);
  const [textImagePref, setTextImagePref] = useState(true);

  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isImageToggleOn, setIsImageToggleOn] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const colDropDownItemClicked = (col) => {
    setMenuOpen(false);
    setIsToggleOn(!isToggleOn);
    setColNum(col);
  };

  const imgDropDownItemClicked = (pref) => {
    setMenuOpen(false);
    setIsImageToggleOn(!isImageToggleOn);
    setTextImagePref(pref);
  };

  // const hideImageDropDown = () => {
  //   setIsImageToggleOn(false);
  // };

  const show = menuOpen ? "show" : "";
  // const navBorderRadius = menuOpen ? "navbar-radius" : "";

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav className={"navbar navbar-expand-lg navbar-dark custom-navbar"}>
          <a className="nav-link-logo" href="/">
            {/* <img src={suitLogo} className="nav-logo" /> */}
            <h1 className="nav-company-name">Bowties Tuxedo</h1>
          </a>

          <button
            className="navbar-toggler no-bs-border"
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={"collapse  navbar-collapse " + show}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              <NavItem path="/" name="Home" />
              <NavItem path="/gallery" name="Gallery" />
              <NavItem path="/services" name="Services" />
              <NavItem path="/measurements" name="Measurements" />
              <NavItem path="/contact" name="Contact" />
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Nav;
