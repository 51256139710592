import React from "react";
import About from "../components/About/About";
import Home from "../components/Home/Home";
import Contact from "../components/Contact/Contact";
// import Work from "../components/Work/Work";
import Gallery from "../components/Gallery/Gallery";
import Services from "../components/Services/Services";
import Measurements from "../components/Measurements/Measurements";

const routes = {
  "/": () => <Home />,
  "/about": () => <About />,
  "/gallery": () => <Gallery />,
  "/services": () => <Services />,
  "/measurements": () => <Measurements />,
  "/contact": () => <Contact />,
};
export default routes;
