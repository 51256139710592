import React, { useState, useEffect } from "react";

import { Col } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.css";
import "./Services.css";
// import "../theme.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
// import contactImg from "../../resources/email.svg";
import FA from "react-fontawesome";

import fittingRoomFix from "../../resources/fitting-room-fix.svg";

function Services(props) {
  return (
    <React.Fragment>
      <Nav />
      <div className="container services-container">
        <div className="content-wrapper">
          {/* <img src={contactImg} className="center-image" /> */}

          <h1 className="custom-header services-header">
            Bowties Tuxedos Services
          </h1>
          <img
            className="services-img"
            src="https://assets2.mr-mag.com/wp-content/uploads/2017/07/Ike-Behar.jpg"
            alt=""
          />
          <ul className="services-list">
            <div className="services-list-items-container">
              <li>Same Day Service</li>
              <li>Tux & Gown Rental Packages</li>
              <li>Delivery & Pick Up (fees apply)</li>
              <li>In-Room Fitting & Delivery (fees apply)</li>
              <li>Large Selection</li>
              <li>Great Prices & Packages</li>
            </div>
          </ul>

          {/* <div className="container">
            <div className="row">
              <div className="col-sm services-list-item">
                <img className="services-icon" src={fittingRoomFix}></img>

                <div className="services-card">Same Day Service</div>
              </div>

              <div className="col-sm services-list-item">
                <img className="services-icon" src={fittingRoomFix}></img>

                <div className="services-card">
                  Tuxedo and Gown Rental Packages
                </div>
              </div>

              <div className="col-sm services-list-item">
                <img className="services-icon" src={fittingRoomFix}></img>

                <div className="services-card">
                  Delivery and Pick Up (fees apply)
                </div>
              </div>
            </div> */}

          {/* <div className="row">
              <div className="col-sm services-list-item">
                <img className="services-icon" src={fittingRoomFix}></img>
                <div className="services-card">
                  In-Room Fitting and Delivery (fees apply)
                </div>
              </div>

              <div className="col-sm services-list-item">
                <img className="services-icon" src={fittingRoomFix}></img>
                <div className="services-card">Large Selection</div>
              </div>

              <div className="col-sm services-list-item">
                <img className="services-icon" src={fittingRoomFix}></img>

                <div className="services-card">Great Prices and Packages</div>
              </div>
            </div>
          </div> */}

          <div className="faq">
            <h1 className="custom-header services-header">FAQ</h1>
            <p className="faq-question">
              How long before the wedding should I reserve a tuxedo?
            </p>
            <p>
              Depending on the season. We do offer same day service, however we
              recommend calling in advance.
            </p>
            <p className="faq-question">What does the rental include?</p>
            <p>
              All of our tuxedo rentals come complete with any color shirt,
              vest, and tie, as well as shoes!
            </p>
            <p className="faq-question">
              What if I have groomsmen out of town?
            </p>
            <p>
              Not a problem! The guys can go to any tuxedo shop near them, and
              call in the measurements.
            </p>
            <p className="faq-question">
              What happens if we get there and the tuxedo doesn't fit?
            </p>
            <p>
              We keep a large stock for exchanges, and we do all of our
              alterations on site.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
export default Services;
