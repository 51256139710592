import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

import { checkValidEmail, checkValidName } from "../../services/util";

import contactImg from "../../resources/email.svg";
import FA from "react-fontawesome";
// import loadingWheelLottie from "../../lotties/loading-wheel.json";
// import UncontrolledLottie from "../UncontrolledLottie/UncontrolledLottie";

function Contact(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSendingAlert, setShowSendingAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const defaultErrorMessage = "Something went wrong...";
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  useEffect(() => {
    setShowFailAlert(false);
  }, [name]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [email]);

  const handleSubmit = () => {
    // const templateId = "bowties-tuxedo-contact-template";
    const templateId = "kbd_contact_form";

    let validEmail = checkValidEmail(email);
    let validName = checkValidName(name);

    if (validEmail && validName) {
      setShowFailAlert(false);
      setErrorMessage(defaultErrorMessage);
      // sendFeedback(templateId, {
      //   message_html: message,
      //   from_name: name,
      //   reply_to: email,
      // });

      sendFeedback(templateId, {
        user_message: message,
        user_name: name,
        user_email: email,
        client_email: "bowtiestuxedolv@yahoo.com",
        client_name: "Jason",
      });

      setName("");
      setEmail("");
      setMessage("");
    } else {
      console.log("setting error message?");
      if (!validEmail) {
        setErrorMessage("Invalid Email Address");
      }
      if (!validName) {
        setErrorMessage("Name cannot be empty");
      }
      console.log("showing fail alert");
      setShowFailAlert(true);
    }
  };

  const sendFeedback = (templateId, variables) => {
    setShowSendingAlert(true);
    window.emailjs
      .send("cs_gmail", templateId, variables)
      .then((res) => {
        console.log("Email successfully sent!");
        setShowSuccessAlert(true);
        setShowSendingAlert(false);
        setTimeout(function () {
          setShowSuccessAlert(false);
        }, 3000);
      })
      .catch((err) => {
        setShowFailAlert(true);
        setTimeout(function () {
          setShowFailAlert(false);
        }, 3000);
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  };

  return (
    <React.Fragment>
      <Nav />
      <div className="container contact-container">
        <div className="content-wrapper">
          <h1 className="custom-header contact-header">
            Contact Bowties Tuxedos
          </h1>
          <div className="mb-4">
            <iframe
              width="100%"
              height="400px"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.942037455675!2d-115.09171858443165!3d36.0705178301054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8d0034c855555%3A0xe90faf237dae8524!2sBowties%20Tuxedo!5e0!3m2!1sen!2sus!4v1596176505130!5m2!1sen!2sus&key=AIzaSyC-h4ZGT7i2KKHi27Ya5M443x-WFDtNeL0"
            ></iframe>
          </div>

          <div className=" contact-details mb-4">
            <h3>Contact Details</h3>
            <p>
              3899 E Sunset Rd #102
              <br />
              Las Vegas, NV 89120
              <br />
            </p>
            <p>
              <abbr title="Phone">Phone</abbr>:{" "}
              <a href="tel:+17028894968" className="outerlink">
                (702) 889-4968
              </a>
            </p>
            <p>
              <abbr title="Email">Email</abbr>:
              <a href="mailto:bowtiestuxedolv@yahoo.com" className="outerlink">
                {" "}
                bowtiestuxedolv@yahoo.com
              </a>
            </p>
            <p>
              <abbr title="Hours">Hours</abbr>: <br />
              Monday: Closed
              <br />
              Tuesday - Friday: 10 AM to 6 PM
              <br />
              Saturday: 10 AM to 4 PM
              <br />
              Sunday: 10 AM to 2 PM
              <br />
            </p>
            <a href="tel:+17024565688" className="outerlink appointment-link">
              <p>Call to Make an Appointment</p>
            </a>
            {/* <a
              className="btn btn-lg btn-primary btn-block"
              href="https://app.bridallive.com/bl-server/appointmentScheduler/b78b5509/wrapper.shtml?lang=en"
            >
              Book Your Appointment Now
            </a> */}
            <p>
              Bowties Tuxedo is a family owned and operated business. We have
              been in the wedding industry for many years in the Las Vegas
              valley. We are conveniently located near McCarran airport, and
              just off the Las Vegas Strip. We offer a large in stock selection
              of colors and sizes for all occasions. All of our tuxedo rentals
              come complete with shoes at no additional cost. We offer in-room
              fittings by appt. and delivery and pick up services as well. Feel
              free to drop by anytime, or give us a call. We look forward to
              meeting you!
            </p>
          </div>

          <div className="contact-form text-left">
            <h3>Send Bowties Tuxedo a Message</h3>
            <form>
              <div className="form-group">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="">Message</label>
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  placeholder="Add Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              {showSuccessAlert && (
                <div className="alert success-alert">
                  <p>Email successfully sent!</p>
                </div>
              )}

              {showSendingAlert && (
                <div className="alert sending-alert">
                  <p>Sending Email</p>
                  {/* <div className="lottie-container">
                    <UncontrolledLottie
                      lottieJson={loadingWheelLottie}
                      height={50}
                      width={50}
                    />
                  </div> */}
                </div>
              )}

              {showFailAlert && (
                <div className="alert fail-alert">
                  <p>{errorMessage}</p>
                </div>
              )}

              <input
                type="button"
                value="Submit"
                className="contact-btn"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
export default Contact;
